<template>
  <router-view/>  
</template>

<script>


export default {
  name: 'App',
  components: {

  }
}
</script>

<style>

</style>
